import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, take } from "rxjs";
import { IShippingCompany, IShippingCompanySettings } from "@appcore/models/shipping-company";
import { AuthHttpService } from "@appcore/services/auth-http.service";
import { ReportType } from "@appcore/services/report.service";

@Injectable({
    providedIn: 'root'
})
export class SelectedCompany {

    private _shippingCompanySubject = new ReplaySubject<number>(1);
    private _shippingCompanyNameSubject = new ReplaySubject<string>(1);
    private _info: Map<number, IShippingCompany> | undefined;
    private fetchInfo = true;
    private _isReady = new ReplaySubject<boolean>(1);

    shippingCompany: Observable<number> = this._shippingCompanySubject;
    shippingCompanyName: Observable<string> = this._shippingCompanyNameSubject;
    isReady: Observable<boolean> = this._isReady;
    lastShippingCompany = 0;
    useNonLotMode = false;
    restingHoursEnabled = false;
    disbursementsEnabled = false;
    travelAllowanceEnabled = false;
    seagoingServiceEnabled = false;
    timeRegistrationEnabled = false;
    crewCheckInEnabled = false;
    chemicalsLogEnabled = false;
    assignments = false;
    disbursementEmailRecipient = "";
    departments = false;
    tasks = false;
    tariffs = false;
    appraisals = false;
    competency = false;
    smsEnabled = false;
    dayBalance = false;
    settings: IShippingCompanySettings | undefined;
    reportAccesses: ReportType[] = [];
    payroll = false;
    watchRota = false;
    talentAndPlacement = false;

    constructor(private authHttp: AuthHttpService) {
    }

    set(id: number) {
        if (id == this.lastShippingCompany)
            return;
        this.lastShippingCompany = id;
        this._shippingCompanySubject.next(id);

        if (this.fetchInfo) {
            this.fetchInfo = false;
            this.authHttp.get<IShippingCompany[]>('/api/shared/shipping-company')
                .pipe(take(1), map(c => c.body))
                .subscribe(companies => {
                    this._info = new Map((companies || []).map(company => [company.id, {...company}]));
                    this.setInfo();
                });
        } else {
            this.setInfo();
        }

    }

    private setInfo() {
        if (this._info == null)
            return;

        const company = this._info.get(this.lastShippingCompany);
        this.useNonLotMode = !company?.settings.lot ?? false;
        this.restingHoursEnabled = company?.settings.restingHours ?? false;
        this.disbursementsEnabled = company?.settings.disbursements ?? false;
        this.travelAllowanceEnabled = company?.settings.travelAllowance ?? false;
        this.seagoingServiceEnabled = company?.settings.seagoingService ?? false;
        this.timeRegistrationEnabled = company?.settings.timeRegistration ?? false;
        this.crewCheckInEnabled = company?.settings.crewCheckIn ?? false;
        this.chemicalsLogEnabled = company?.settings.chemicalsLog ?? false;
        this.assignments = company?.settings.assignments ?? false;
        this.departments = company?.settings.departments ?? false;
        this.disbursementEmailRecipient = company?.settings.disbursementEmailRecipient ?? "";
        this.tasks = company?.settings?.tasks ?? false;
        this.tariffs = company?.settings?.tariffs ?? false;
        this.appraisals = company?.settings?.appraisals ?? false;
        this.competency = company?.settings.competency ?? false;
        this.dayBalance = company?.settings.dayBalance ?? false;
        this.smsEnabled = company?.settings.sms ?? false;
        this._isReady.next(true);
        this.settings = company?.settings;
        this.reportAccesses = company ? company.reportAccesses : [];
        this.payroll = company?.settings.payroll ?? false;
        this.watchRota = company?.settings.watchRota ?? false;
        this.talentAndPlacement = company?.settings.talentAndPlacement ?? false;

        if (company != null)
            this._shippingCompanyNameSubject.next(company.name)
    }

    public hasReportAccess(reportType: ReportType): boolean {
        return this.reportAccesses.indexOf(reportType) > -1;
    }

    public hasAnyReportAccess(reportTypes: ReportType[]): boolean {
        for (let reportType of reportTypes) {
            if (this.hasReportAccess(reportType)) {
                return true;
            }
        }

        return false;
    }
}
