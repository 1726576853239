import { DateRouteOption } from "@appcore/models";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { HttpResponse } from "@angular/common/http";
import { saveAs } from "file-saver";
import { Validators } from "@angular/forms";
import Compressor from "compressorjs";

/**
 * group the list by the provided key
 */
export function groupBy<T, TKey>(list: T[], key: (item: T) => TKey) {
    const map = new Map<TKey, T[]>();

    list.forEach((item) => {
        const ix = key(item);
        const collection = map.get(ix);
        if (collection == null) {
            map.set(ix, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
}

/**
 * Generate a list of quarters within the specified years, ordered by the or
 * @param years
 */
export function createQuarterOptions(years: number[]): DateRouteOption[] {
    const now = DateTime.now();
    const quarters = [1, 2, 3, 4];
    const options: DateRouteOption[] = [];

    for(const y of years) {
        const janFirst = DateTime.fromFormat(y.toString(), 'yyyy');
        for(const q of quarters) {
            const start = janFirst.plus({quarter: q - 1}).startOf('quarter');
            const end = start.endOf('quarter');
            options.push({
                id: `${q}-${y}`,
                fromDate: start.toISODate(),
                toDate: end.toISODate(),
                label: `${q} Kvartal - ${y}`,
                isCurrent: (y === now.year && (start.quarter === now.quarter || end.quarter === now.quarter)),
            });
        }
    }

    return options;
}


export function createYearsOptions(years: number[]): DateRouteOption[] {
    const now = DateTime.now();
    const options: DateRouteOption[] = [];

    for(const y of years) {
        const janFirst = DateTime.fromFormat(y.toString(), 'yyyy');
        const decLast = janFirst.plus({years: 1, days: -1});
        options.push({
            id: `${y}`,
            fromDate: janFirst.toISODate(),
            toDate: decLast.toISODate(),
            label: `${y}`,
            isCurrent: (y === now.year),
        });
    }

    return options;
}

/**
 * Returns the object without all values that were null
 * @param obj
 */
export function removeNulls<T extends object>(obj: T): T {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
    ) as T;
}

export function blobDownloadHelper(blob: HttpResponse<Blob>) {
    if (!blob.body) {
        return;
    }

    const cd = blob.headers.get("Content-Disposition");

    if (!cd) {
        return;
    }

    const match = cd.match(/filename=(.*);/);

    if (!match) {
        return;
    }

    const filename = match[1];

    if (shouldUseFileSaver()) {
        saveAs(blob.body, filename);
        return;
    }

    const url = window.URL.createObjectURL(blob.body);

    let anchor = document.createElement("a");
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
}

export function shouldUseFileSaver(): boolean {
    return (/(iPad|iPhone|iPod)/g.test(navigator.platform || navigator.userAgent) || ((navigator.platform || navigator.userAgent) === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.hasOwnProperty("MSStream");
}

export function isSkamlaust() {
    return document.cookie.match(/^(.*;)?\s*Skamlaust\s*=\s*[^;]+(.*)?$/);
}

/**
 * Extract yyyy-mm-dd from ISO date string
 * @param date
 */
export function extractDate(date: string) {
    return date.split("T")[0];
}

export function extractHoursMinutes(date: string) {
    return date.split("T")[1].slice(0, 5);
}

/**
 * Remove hours, minutes, seconds and timezone from ISO date string
 * @param date
 */
export function setDateToMidnightUTC(date: string) {
    return extractDate(date) + "T00:00:00+00:00";
}

export function formatDecimal(decimal: number) {
    return decimal.toFixed(1);
}

export function dateIsInThePast(date: string | undefined) {
    if (!date) {
        return false;
    }

    return new Date(date) < new Date();
}

/**
 * takes any image file and
 * returns a jpg image with width and height of 100px quality of 0.8
 * if the image has a different aspect ratio, it will be cropped to 1:1 ratio from the center (object-fit: cover)
 * @param file
 * @returns {Promise<File>}
 */
export async function resizeImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject('Could not get canvas context');
                    return;
                }
                const maxSize = 200;

                let width = img.width;
                let height = img.height;

                // Calculate new dimensions while maintaining an aspect ratio
                if (width > height) {
                    height = maxSize;
                    width = (maxSize / img.height) * img.width;
                } else {
                    width = maxSize;
                    height = (maxSize / img.width) * img.height;
                }

                // Center-crop the image
                const offsetX = (width - maxSize) / 2;
                const offsetY = (height - maxSize) / 2;

                // Set canvas dimensions and draw the image
                canvas.width = maxSize;
                canvas.height = maxSize;
                ctx.drawImage(
                    img,
                    -offsetX,
                    -offsetY,
                    width,
                    height
                );

                // Convert the canvas to a data URL with JPG format and quality of 0.8
                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            reject('Could not convert canvas to blob');
                            return;
                        }
                        const resizedFile = new File([blob], file.name, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        });
                        resolve(resizedFile);
                    },
                    'image/jpeg',
                    0.8
                );
            };

            img.onerror = function (error) {
                reject(error);
            };

            img.src = reader.result as string;
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
}

export function capitalizeValue(value: string) {
    return value.toLowerCase()
        .split(' ')
        .map((namePart: string) => namePart.charAt(0).toUpperCase() + namePart.substring(1)).join(' ');
}

export class FormValidation {
    static nameValidation: Validators = [Validators.minLength(2), Validators.maxLength(100), Validators.required];
    static nameValidationNotRequired: Validators = [Validators.minLength(2), Validators.maxLength(100)];
    static phoneValidation: Validators = [Validators.required, Validators.maxLength(20), Validators.minLength(8), Validators.pattern(/^[+0-9]{1}[0-9]{1,14}$/)]
    static phoneValidationNotRequired: Validators = [Validators.maxLength(20), Validators.minLength(8), Validators.pattern(/^[+0-9]{1}[0-9]{1,14}$/)]
}

export function sortStringsAlphabetical(a: string, b: string) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }

    return 0;
}

/**
 * Takes in timestamp and returns hours and minutes (hh:mm).
 * @param input Correct format: 2024-01-09T00:00:00+00:00
 */
export function formatTimestampToHoursMinutes(input: string) {
	return input.substring(11, 16);
}

/**
 * Calculate duration between two timestamps. Sending in startDate and endDate with different dates does not make sense.
 * Return format example 12t 30m.
 * @param startDateTimeOffset
 * @param endDateTimeOffset
 */
export function calculateTimeSpent(startDateTimeOffset: any, endDateTimeOffset: any) {
	const startDate = new Date(startDateTimeOffset);
	const endDate = new Date(endDateTimeOffset);

	const timeDifference = endDate.getTime() - startDate.getTime();

	const seconds = Math.floor((timeDifference / 1000) % 60);
	const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
	const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);

	return `${hours}t ${minutes ? minutes + "m" : ""}`;
}

export function calculateAge(dateOfBirth: any) {
	if(dateOfBirth == null)
        return;
    var today = new Date();
	var birthDate = new Date(dateOfBirth);
    if(isNaN(birthDate.valueOf()))
        return;
	var age = today.getFullYear() - birthDate.getFullYear();
	var monthDiff = today.getMonth() - birthDate.getMonth();
	if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
}

export function compressorJs(file: any, quality?: number): any {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (!file || !validImageTypes.includes(file.type)) {
        return null;
    }

    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: quality ? quality : 0.6,
            success(result: File | Blob) {
                let compressedFile = new File([result], file.name, {type: result.type});
                resolve(compressedFile);
            },
            error(err) {
                console.error(err.message);
                reject(err);
            },
        });
    });
}

export function getPreviousMonth(monthName: string): string {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthIndex = months.indexOf(monthName);

    if (monthIndex === -1) {
        throw new Error("Invalid month name");
    }

    const previousMonthIndex = (monthIndex === 0) ? 11 : monthIndex - 1;
    return months[previousMonthIndex];
}

export function stringContainsNonAscii(str: string): boolean {
    return !/^[\u0000-\u007f]*$/.test(str);
}

/**
 * Takes in a date string and returns transloco string for short weekdays (Mon, Tue, Wed etc)
 * @param dateString - string
 */
export function parseDateIntoShortWeekday(dateString: string): string {
    let weekdays = [
        "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
    ];
    let date = new Date(dateString);
    return "shared.day.short." + weekdays[date.getDay()];
}

/**
 * Check if date is today
 * @param fromDate
 */
export function isToday(fromDate: string): boolean {
    const date = new Date(fromDate);
    const today = new Date();
    return date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();
}

export function getDateFromWeek(week: number, day: number): Date {
    // Get the current year
    const currentYear = new Date().getFullYear();

    // Create a date for January 1st of the current year
    const firstDayOfYear = new Date(currentYear, 0, 1);

    // Get the day of the week for January 1st (0 is Sunday, 6 is Saturday)
    const dayOfWeek = firstDayOfYear.getDay();

    // Calculate how many days to add to get to the first Monday of the year
    const daysUntilFirstMonday = (dayOfWeek <= 1) ? (1 - dayOfWeek) : (8 - dayOfWeek);

    // Calculate the date of the first Monday of the year
    const firstMonday = new Date(currentYear, 0, 1 + daysUntilFirstMonday);

    // Calculate the target date by adding weeks and days
    const targetDate = new Date(firstMonday);
    targetDate.setUTCDate(firstMonday.getUTCDate() + (week - 1) * 7 + (day - 1));
    targetDate.setHours(12);

    return targetDate;
}

export function formatDateToNoNbHourMinutes(str: string) {
    let date = {
        format: <DateTimeFormatOptions>{hour: '2-digit', minute: '2-digit'},
        locale: {locale: 'no-nb'}
    };

    if (str == null)
        return '';

    return DateTime.fromISO(str)
        .toLocaleString(date.format, date.locale);
}
